import { useRef, useState } from "react";
import Button from "../Util/Button";
import Loading from "../Util/Loading";
import UserInput from "./UserInput";
import UserSelect from "./UserSelect";

export default function NewPortalUser(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const fname = useRef();
  const lname = useRef();
  const email = useRef();
  const phone = useRef();
  const position = useRef();
  const department = useRef();
  const role = useRef();
  const password = useRef();
  const cpassword = useRef();

   function titleCase(str) {
     var splitStr = str.toLowerCase().split(" ");
     for (var i = 0; i < splitStr.length; i++) {
       splitStr[i] =
         splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
     }
     return splitStr.join(" ");
   }

  const createUser = () => {
    const body = {
      Name: titleCase(fname.current.getValue()),
      Phone: phone.current.getValue(),
      Email: email.current.getValue().toLowerCase().trim(),
      Department: department.current.getValue(),
      Password: password.current.getValue(),
      cpassword: cpassword.current.getValue(),
      Role: role.current.getValue(),
    };
    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      if (
        !validatePassword(body.Password) ||
        !validatePassword(body.cpassword)
      ) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (body.Password !== body.cpassword) {
        result = false;
        setError("Passwords do not match!!!");
        setLoading(false);
        return result;
      }
      if (!body.Phone || body.Phone.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }
      if (!body.Name) {
        result = false;
        setError("Name is reqired!");
        setLoading(false);
        return result;
      }
      if (!body.Department) {
        result = false;
        setError("Department field is required!");
        setLoading(false);
        return result;
      }
      if (!body.Role) {
        result = false;
        setError("Role is required!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      fetch(`/api/auth/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/portal/Users";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="users">
      <div className="list">
        <h3>New Portal User</h3>
        <hr />
        <div className="new">
          <h6>{error}</h6>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <UserInput ref={fname} type="text" label="Name *" />
              <UserInput ref={phone} type="number" label="Phone *" />
            </div>
            <UserInput ref={email} type="email" label="Email *" />

            <div className="div2equal">
              <UserInput ref={department} type="text" label="Department *" />
              <UserSelect ref={role} label="Role *" data={["User", "Admin"]} />
            </div>

            <div className="div2equal">
              <UserInput ref={password} type="password" label="Password *" />
              <UserInput
                ref={cpassword}
                type="password"
                label="Confirm Password *"
              />
            </div>
            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
}
