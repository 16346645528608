import "../../Styles/data.scss";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import Button from "../Util/Button";
import Input from "../Util/Input";
import { isMobile } from "react-device-detect";

export default function Data(props) {
  const [data, setData] = useState(null);
  const [head, setHead] = useState(null);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(null);
  const [editing, setEditing] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${props.url}/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "nationalid" &&
              item.toLowerCase() !== "farmingtype" &&
              item.toLowerCase() !== "id"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [offset]);

  return (
    <div className="data">
      <div className="list">
        <h3>{props.title}</h3>
        <hr />
        <div
          style={{
            gridTemplateColumns: `repeat(${head ? count : 0},1fr)`,
          }}
          className="head"
        >
          {head &&
            head.length > 0 &&
            head.map((item, i) => {
              if (i < count) {
                return <h4 key={i}>{item}</h4>;
              }
            })}
        </div>
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <Item
                setShowing={setShowing}
                key={i}
                index={i}
                data={item}
                head={head}
                count={count}
              />
            );
          })}

        {data?.total && (
          <Pagination total={data?.total} setOffset={setOffset} page={offset} />
        )}
        {showing !== null && (
          <Popup
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);
  const [cls, setCls] = useState(null);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState("Basic");

  useEffect(() => {
    setIsLoading(true);
    setCols(null);
    setCls(null);
    setData(null);
    if (active === "Basic") {
      if (props.showing !== null) {
        const cols = Object.keys(props.data[props.showing]);
        let d = [];
        cols.forEach((item) => {
          if (item.toLowerCase() !== "geom") {
            d.push(item);
          }
        });
        setCols(d);
      }
      setIsLoading(false);
    } else {
      let d = "farmeraddress";
      switch (active) {
        case "Address":
          d = "farmeraddress";
          break;
        case "Farm":
          d = "farmerresources";
          break;
        case "Groups":
          d = "farmergroups";
          break;
        case "Value Chains":
          d = "farmervaluechains";
          break;
        default:
          setActive("Basic");
          break;
      }
      fetch(`/api/${d}/${props.data[props.showing].NationalID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.length > 0) {
            setData(data);
            const cols = Object.keys(data[0]);
            let d = [];
            cols.forEach((item) => {
              if (item.toLowerCase() !== "geom") {
                d.push(item);
              }
            });
            setCls(d);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [active]);

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={active === params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="bar">
          <Bar txt="Basic" />
          <Bar txt="Address" />
          <Bar txt="Farm" />
          <Bar txt="Groups" />
          <Bar txt="Value Chains" />
        </div>
        <div className="content">
          {cols &&
            cols.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {props.data[props.showing][item]}
                </p>
              );
            })}
          {cls &&
            cls.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {data[index][item]}
                </p>
              );
            })}
          {isLoading && <WaveLoading />}
          <div className="tally">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return (
                  <p
                    className={i === index ? "active" : ""}
                    onClick={() => {
                      setIndex(i);
                    }}
                    key={i}
                  >
                    {i + 1}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${props.head ? props.count : 0},1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      {props.head.map((item, i) => {
        if (i < props.count) {
          return <p key={i}>{props.data[item]}</p>;
        }
      })}
    </div>
  );
};

const EditData = (props) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [body, setBody] = useState(props.data);
  const [loading, setLoading] = useState(false);

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  useEffect(() => {
    const d = Object.entries(props.data);
    console.log(d);
    setData(d);
  }, [props.data]);

  function updateData() {
    let d = body;
    delete d.geom;
    delete d.createdAt;
    delete d.updatedAt;

    const chck = Object.values(d);
    let rr = true;
    console.log(d);
    chck.map((item) => {
      if (item === "" || item === null) {
        rr = false;
        setError("All fields are required!");
      }
    });
    if (!rr) return;
    if (d.Longitude && !isNumeric(d.Longitude))
      return setError("Invalid Longitude!");
    if (d.Latitude && !isNumeric(d.Latitude))
      return setError("Invalid Latitude!");

    fetch(
      `/api/data/update/${capitalize(props.url.toString().split("/")[3])}/${
        d.id
      }`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(d),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            const d = props.url.toString().split("/")[3];
            if (d == "projects") {
              window.location.href = "/projects/List";
            } else {
              window.location.href = "/data/" + capitalize(d);
            }
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="editdata">
      <div className="cont">
        <div className="dets">
          <h3>Update Data</h3>
          <i
            onClick={() => {
              props.setEditing(null);
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <hr />
        <div className="div2equal">
          {data &&
            data.map((item, i) => {
              if (
                item[0] !== "id" &&
                item[0] !== "geom" &&
                item[0] !== "createdAt" &&
                item[0] !== "updatedAt"
              ) {
                return (
                  <Input
                    body={body}
                    setBody={setBody}
                    key={i}
                    label={item[0]}
                    value={item[1]}
                  />
                );
              }
            })}
        </div>
        <br />
        <h6>{error}</h6>
        <br />
        <Button value="Update" handleClick={updateData} />
        {loading && <WaveLoading />}
      </div>
    </div>
  );
};
