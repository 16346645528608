import { useEffect, useState } from "react";
import "../../Styles/stats.scss";
import BarGraphExpense from "./BarGraphExpense";
import BubbleChartMonth from "./BubbleChartMonths";
import CustomPieChart from "./CustomPieChart";
import CustomBarChart from "./CustomBarChart";
import StackedAreaChart from "./StackedAreaChart";
import HorizontalChart from "./HorizontalChart";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import html2canvas from "html2canvas";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";

export default function Stats(props) {
  const [data, setData] = useState(null);
  const [projects, setProjects] = useState(null);
  const chrt = useRef(null);
  const [showing, setShowing] = useState(true);
  const [aspect, setAspect] = useState(1.5);
  const [aspect1, setAspect1] = useState(1.5);

  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();

  const b1ref = useRef();
  const b2ref = useRef();

  const h1ref = useRef();
  const h2ref = useRef();

  useLayoutEffect(() => {
    const { width, height } = p1ref.current.getBoundingClientRect();

    setAspect(width / height);
    setAspect1(width / (height * 0.8));
  }, []);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    setShowing(false);
    setTimeout(() => {
      setShowing(true);
    }, 1);
  }, [props.showing]);

  useEffect(() => {
    fetch(`/api/farmerdetails/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data)
        setData(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/farmerdetails/charts`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setProjects(data);
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      {showing && (
        <div className="stats">
          <div className="top">
            <TopItem
              title="Farmers"
              amt={data ? data?.Farmers[0].total : 0}
              color="#C9EEFF"
            />
            <TopItem
              title="CIGs"
              amt={data ? data?.CIG[0].total : 0}
              color="#F1DEC9"
            />
            <TopItem
              title="POs"
              amt={data ? data?.PO[0].total : 0}
              color="#FBFFB1"
            />
            <TopItem
              title="Livestock Acreage"
              amt={data ? data?.LivestockA[0].total : 0}
              color="#FFA3FD"
            />
            <TopItem
              title="Crop Acreage"
              amt={data ? data?.CropA[0].total : 0}
              color="#C9F4AA"
            />
            <TopItem
              title="Total Acreage"
              amt={data ? data?.TotalA[0].total : 0}
              color="#FFFBEB"
            />
          </div>
          <div className="pies">
            <div ref={p1ref} className="chart">
              <h3>Farming Type</h3>
              {projects && (
                <CustomPieChart data={projects?.Type} aspect={aspect} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Type);
                  }}
                />
              </div>
            </div>
            <div ref={p2ref} className="chart">
              <h3>Age Distribution</h3>
              {projects && (
                <CustomPieChart data={projects?.Year} aspect={aspect} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Year);
                  }}
                />
              </div>
            </div>
            <div ref={p3ref} className="chart">
              <h3>Gender Distribution</h3>
              {projects && (
                <CustomPieChart data={projects?.Expense} aspect={aspect} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p3ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Expense);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="middle">
            <div ref={b1ref} className="chart">
              <h3>Distribution by Sub County</h3>
              {projects && (
                <CustomBarChart data={projects?.SubCounty} aspect={aspect1} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(b1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.SubCounty);
                  }}
                />
              </div>
            </div>
            <div ref={b2ref} className="chart">
              <h3>Distribution by Ward</h3>
              {projects && (
                <CustomBarChart data={projects?.Ward} aspect={aspect1} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(b2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Ward);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bottom">
            <div ref={h1ref} className="chart">
              <h3>Farmer Groups</h3>
              {projects && (
                <HorizontalChart
                  data={projects?.Classification}
                  aspect={aspect1}
                />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(h1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Classification);
                  }}
                />
              </div>
            </div>
            <div ref={h2ref} className="chart">
              <h3>Value Chains</h3>
              {projects && (
                <HorizontalChart data={projects?.Department} aspect={aspect1} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(h2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Department);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const TopItem = (props) => {
  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div
      style={{ backgroundColor: props.color ?? props.color }}
      className="tp_item"
    >
      <p>{props.title}</p>
      <h1>{withCommas(props.amt)}</h1>
    </div>
  );
};
