import { React } from "react";

function NotFound() {
  return (
    <div>
      <p
        data-aos={"fade-up"}
        data-aos-offset={300}
        data-aos-duration={900}
        data-aos-delay={600}
      >
        Not Found! You may not be authorised to access this page. For more
        information Contact administrator.
      </p>
    </div>
  );
}

export default NotFound;
