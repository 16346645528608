import { useEffect } from "react";
import { useState } from "react";
import Query from "./Query";
import VectorLayer from "ol/layer/Vector";

export default function RightPanel(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [active, setActive] = useState("Layers");

  useEffect(() => {
    if (props.showing) {
      setCollapsed(true);
    }
  }, [props.showing]);

  return (
    <div className="map_panel">
      <div className="outer">
        <i
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          className={collapsed ? "fa fa-angle-left" : "fa fa-angle-right"}
        ></i>
        {!collapsed && (
          <div
            data-aos={collapsed ? "fade-right" : "fade-right"}
            className="collapsible"
          >
            <div className="bar">
              <Item txt="Layers" active={active} setActive={setActive} />
              <Item txt="Query" active={active} setActive={setActive} />
            </div>
            {active === "Layers" && (
              <Layers map={props.map}  />
            )}
            {active === "Query" && (
              <Query
                map={props.map}
                setData={props.setData}
                setIsLoading={props.setIsLoading}
                setActive={setActive}
                setExtent={props.setExtent}
                setFilter={props.setFilter}
                filter={props.filter}
                resetMap={props.resetMap}
                removeByTitle={props.removeByTitle}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <p
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.txt === props.active ? "active" : ""}
    >
      {props.txt}
    </p>
  );
};

const Layers = (props) => {
  const [layrs, setLayrs] = useState([]);

  useEffect(() => {
    if (props?.map?.getAllLayers()?.length !== layrs.length) {
      let d = [];
      props.map?.getAllLayers().map((item, i) => {
        d.push({
          title: item.get("title"),
          index: i,
          checked: item.getVisible(),
        });
      });
      setLayrs(d);
    }
  }, [props.map, props?.map?.getAllLayers(), layrs]);

  function togglelayer(index, oldIndex) {
    if (props.map) {
      let d = array_move(props.map.getAllLayers(), index, oldIndex);
      setLayrs([]);

      props.map.getAllLayers().map((layer) => {
        props.map.removeLayer(layer);
      });
      d.map((layer) => {
        props.map.addLayer(layer);
      });
    }
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  const Layer = (params) => {
    const [checked, setChecked] = useState(params.item.checked);
  

    return (
      <>
        <div className="item">
          <input
            onChange={(e) => {
              setChecked(e.target.checked);
              params.map
                .getAllLayers()
                [params.item.index].setVisible(e.target.checked);
            }}
            type="checkbox"
            name=""
            id=""
            checked={checked}
          />
          <p>{params.item.title}</p>
          <i
            onClick={() => {
              try {
                params?.map?.getAllLayers().map((layer) => {
                  if (layer instanceof VectorLayer) {
                    const d = layer?.getSource()?.getExtent();
                    console.log(d);
                    if (d !== undefined) {
                      params.map.getView().fit(d);
                    } else alert("Layer does not have data");
                  } else alert("No extent");
                });
              } catch (error) {}
            }}
            className="fa fa-arrows-alt"
          ></i>
          <i
            onClick={() => {
              if (params.item.index !== 0) {
                params.togglelayer(params.item.index, params.item.index - 1);
              }
            }}
            className="fa fa-angle-double-up"
          ></i>
          <i
            onClick={() => {
              if (params.item.index + 1 < params?.map?.getAllLayers()?.length) {
                params.togglelayer(params.item.index, params.item.index + 1);
              }
            }}
            className="fa fa-angle-double-down"
          ></i>
        </div>
        
      </>
    );
  };



  return (
    <div className="r_layers">
      <h4>Map Layers</h4>
      <hr />
      {layrs.length > 0 &&
        layrs.map((item) => {
          return (
            <Layer
              key={item.index}
              togglelayer={togglelayer}
              item={item}
              map={props.map}
      
            />
          );
        })}
    </div>
  );
};
